import { useState, useEffect } from "react";
import "./MissionsScreen.css";
import { getMissions, completeMission } from "../back/api";
import { toast } from "react-toastify";
import { Mission } from "../models/MissionModel";
import { usePlayer } from "../context/PlayerContext";
import { GiNestBirds } from "react-icons/gi";
import { ClipLoader } from "react-spinners";

const MissionScreen = () => {
  const { birdsPoints, setBirdsPoints, playerId } = usePlayer();
  const [missions, setMissions] = useState<Mission[]>([]);
  const [completedMissions, setCompletedMissions] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  // Récupérer les missions au chargement du composant
  useEffect(() => {
    const fetchMissions = async () => {
      try {
        setLoading(true);
        const missionsData = await getMissions(playerId);
        setMissions(missionsData);
      } catch (error) {
        console.error("Error fetching missions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchMissions();
  }, [playerId]);

  const handleCompleteMission = async (mission: Mission) => {
    if (completedMissions.includes(mission.id)) {
      toast.info("Mission already completed!");
      return;
    }

    try {
      // Effectuer l'appel API pour compléter la mission
      await completeMission(playerId, mission.id);

      const newBirdsPoints = birdsPoints + mission.reward;
      setBirdsPoints(newBirdsPoints);

      // Marquer la mission comme complétée
      setCompletedMissions([...completedMissions, mission.id]);

      toast.success(`Mission completed! +${mission.reward} birds points`);
    } catch (error) {
      toast.error("Error completing mission, try later!");
      console.error("Error completing mission:", error);
    }
  };

  return (
    <div className="missionScreen-container">
      <h1 className="mission-title">Missions</h1>
      {loading ? (
        <div className="loader">
          <ClipLoader size={50} color="#123abc" loading={loading} />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="missions-container">
          {missions.map((mission) => {
            const isCompleted = completedMissions.includes(mission.id);
            return (
              <button
                key={mission.id}
                className={`mission-item ${isCompleted ? "completed" : ""}`}
                onClick={() => handleCompleteMission(mission)}
                disabled={isCompleted}
              >
                <div className="mission-details">
                  <h2>{mission.title}</h2>
                  <div className="mission-reward">
                    {mission.reward}{" "}
                    <GiNestBirds
                      style={{ color: "#4FFF57", fontSize: "20px" }}
                    />
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MissionScreen;
