import React, { useState, useEffect } from "react";
import { getLeaderboard } from "../back/api"; // Importez les fonctions API nécessaires
import "./LeaderBoardScreen.css";
import { Leaderboard } from "../models/LeaderboardModel";
import { ClipLoader } from "react-spinners";
import { GiNestBirds } from "react-icons/gi";

const LeaderboardScreen = () => {
  const [leaderboard, setleaderboard] = useState<Leaderboard[]>([]);
  const [debugConsole, setDebugConsole] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchleaderboard = async () => {
      try {
        setLoading(true);
        const leaderboardList: Leaderboard[] = await getLeaderboard();
        setleaderboard(leaderboardList);
      } catch (error) {
        console.error("Erreur lors de la récupération des amis:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchleaderboard();
  }, []);

  return (
    <div className="leaderboardScreen-container">
      {debugConsole && <div className="console">{debugConsole}</div>}
      <h1 className="leaderboard-title">Leaderboard</h1>

      {loading ? (
        <div className="loader">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="leaderboard-list-container">
          <div className="leaderboard-list">
            {leaderboard && leaderboard.length > 0 && (
              <ul>
                {leaderboard.map((leader, index) => (
                  <li key={leader.first_name}>
                    #{index + 1} {leader.first_name}
                    <div className="birdsPoints">
                      {leader.birdsPoints}
                      <GiNestBirds
                        style={{ color: "#4FFF57", fontSize: "20px" }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaderboardScreen;
