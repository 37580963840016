import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"; // Importer motion
import Header from "./Header";
import Footer from "./Footer";
import "./styles/App.css";
import BirdsScreen from "./screens/BirdsScreen";
import FriendsScreen from "./screens/FriendsScreen";
import LeaderBoardScreen from "./screens/LeaderBoardScreen";
import { usePlayer } from "./context/PlayerContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpgradesScreen from "./screens/UpgradesScreen";
import MissionScreen from "./screens/MissionsScreen";

const App: React.FC = () => {
  const [activeScreen, setActiveScreen] = useState("Birds");
  const { birdsPoints, setBirdsPoints } = usePlayer();

  const renderScreen = () => {
    switch (activeScreen) {
      case "Upgrades":
        return <UpgradesScreen />;
      case "Birds":
        return <BirdsScreen />;
      case "Friends":
        return <FriendsScreen />;
      case "LeaderBoard":
        return <LeaderBoardScreen />;
      case "Mission":
        return <MissionScreen />;
      // Ajoutez d'autres cas pour d'autres écrans
      default:
        return <BirdsScreen />;
    }
  };

  // Charger les birdsPoints à partir de la base de données au chargement de l'application
  useEffect(() => {
    const fetchBirdsPoints = async () => {
      try {
        const response = await fetch("/api/getBirdsPoints");
        const data = await response.json();
        setBirdsPoints(data.birdsPoints);
      } catch (error) {
        console.error("Erreur lors du chargement des birdsPoints :", error);
      }
    };

    fetchBirdsPoints();
  }, [setBirdsPoints]);

  // Sauvegarder les birdsPoints en base de données toutes les 2 secondes
  useEffect(() => {
    const saveBirdsPoints = async () => {
      try {
        await fetch("/api/saveBirdsPoints", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ birdsPoints }),
        });
      } catch (error) {
        console.error("Erreur lors de la sauvegarde des birdsPoints :", error);
      }
    };

    const interval = setInterval(saveBirdsPoints, 2000);
    return () => clearInterval(interval); // Nettoyage de l'intervalle à la fin du cycle de vie
  }, [birdsPoints]);

  return (
    <div className="app">
      <Header />
      <motion.div
        key={activeScreen} // Pour que l'animation se déclenche lors du changement d'écran
        initial={{ opacity: 0, y: 0 }} // État initial
        animate={{ opacity: 1, y: 0 }} // État final
        transition={{ duration: 0.5 }} // Durée de l'animation
        className="appMotionDiv"
      >
        {renderScreen()}
      </motion.div>
      <Footer setActiveScreen={setActiveScreen} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        toastStyle={{ backgroundColor: "rgb(0 50 121)", color: "white" }}
      />
    </div>
  );
};

export default App;
