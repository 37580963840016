import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FaClipboard, FaUserFriends } from "react-icons/fa";
import { getFriends } from "../back/api"; // Importez les fonctions API nécessaires
import "./FriendsScreen.css";
import { usePlayer } from "../context/PlayerContext";
import { Friend } from "../models/FriendsModel";
import { ClipLoader } from "react-spinners";
import { GiNestBirds } from "react-icons/gi";

const FriendsScreen = () => {
  const { playerId } = usePlayer();

  const [friends, setFriends] = useState<Friend[]>([]);
  const [debugConsole, setDebugConsole] = useState("");
  const [loading, setLoading] = useState(true);

  const telegramLink = `https://t.me/BirdsInvasionBot?start=${playerId}`;

  useEffect(() => {
    const fetchFriends = async () => {
      try {
        setLoading(true);
        const friendsList: Friend[] = await getFriends(playerId);
        setFriends(friendsList);
      } catch (error) {
        console.error("Erreur lors de la récupération des amis:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchFriends();
  }, []);

  const handleCopyLink = () => {
    if (playerId) {
      navigator.clipboard
        .writeText(telegramLink)
        .then(() => toast.success("Link copied!"))
        .catch(() => toast.error("Error when link copied"));
    }
  };

  const handleInviteAFriend = () => {
    const message =
      "Hello my friend, come and catch birdies with me to earn points and future Mission ⭐😊! " +
      telegramLink;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
      message
    )}`;

    window.open(telegramUrl, "_blank");
  };

  return (
    <div className="friendsScreen-container">
      {debugConsole && <div className="console">{debugConsole}</div>}
      <h1 className="friends-list-title">Friends list</h1>

      {loading ? (
        <div className="loader">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="friends-list-container">
          <div>
            <div className="friends-list">
              <h3>
                <div className="coins">
                  +500{" "}
                  <GiNestBirds style={{ color: "#4FFF57", fontSize: "30px" }} />
                  per friend invited
                </div>
              </h3>
              {friends && friends.length > 0 ? (
                <ul>
                  {friends.map((friend) => (
                    <li key={friend.first_name}>
                      {friend.first_name}
                      <div className="birdsPoints">
                        {friend.birdsPoints}
                        <GiNestBirds
                          style={{ color: "#4FFF57", fontSize: "20px" }}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>You haven't invited any friends yet.</p>
              )}
            </div>
          </div>
          <div className="button-container">
            <button onClick={handleInviteAFriend} className="copy-link-button">
              <FaUserFriends />
              <span className="button-text">Invite a Friend</span>
            </button>

            <button onClick={handleCopyLink} className="copy-link-button">
              <FaClipboard />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FriendsScreen;
