export interface Friend {
  id: number;
  first_name: string;
  birdsPoints: number;
}

export const mockFriends: Friend[] = [
  {
    id: 1,
    first_name: "Jack",
    birdsPoints: 1242,
  },
  {
    id: 2,
    first_name: "Mak",
    birdsPoints: 24242,
  },
  {
    id: 3,
    first_name: "Pal",
    birdsPoints: 789,
  },
  {
    id: 4,
    first_name: "Pao",
    birdsPoints: 42342,
  },
  {
    id: 5,
    first_name: "Maok",
    birdsPoints: 0,
  },
  {
    id: 6,
    first_name: "Fifi",
    birdsPoints: 0,
  },
  {
    id: 7,
    first_name: "Bunny",
    birdsPoints: 242,
  },
  {
    id: 8,
    first_name: "Zaz",
    birdsPoints: 45365434,
  },
  {
    id: 9,
    first_name: "Xeze",
    birdsPoints: 2211,
  },
];
