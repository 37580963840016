export interface Mission {
  id: number;
  reward: number;
  title: string;
  description: string;
  link?: string;
}

export const mockMissions: Mission[] = [
  {
    id: 1,
    reward: 100,
    title: "Subscribe to X account",
    description: "You have to subscribe to our X account",
  },
  {
    id: 2,
    reward: 100,
    title: "Subscribe to Instagram account",
    description: "You have to subscribe to our Instagram account",
  },
  {
    id: 3,
    reward: 100,
    title: "Subscribe to Telegram account",
    description: "You have to subscribe to our Telegram account",
  },
  {
    id: 4,
    reward: 1000,
    title: "Invite 3 friends",
    description: "You have to invite 3 friends",
  },
  {
    id: 5,
    reward: 3000,
    title: "Invite 5 friends",
    description: "You have to invite 5 friends",
  },
  {
    id: 6,
    reward: 10000,
    title: "Invite 10 friends",
    description: "You have to invite 10 friends",
  },
  {
    id: 7,
    reward: 50,
    title: "Share X post",
    description: "Share the X post",
    link: "https://x.com/birds_invasion/status/1843996425639854346",
  },
  {
    id: 8,
    reward: 70,
    title: "Like X post",
    description: "Like the X post",
    link: "https://x.com/birds_invasion/status/1843996425639854346",
  },
  {
    id: 9,
    reward: 500,
    title: "Be Smart",
    description: "Like All X post",
    link: "https://x.com/birds_invasion",
  },
  {
    id: 10,
    reward: 50000,
    title: "QI > 150",
    description: "Find the little bird",
  },
];
