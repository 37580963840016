export interface Upgrade {
  upgrade_id: number;
  upgrade_title: string;
  upgrade_description: string;
  upgrade_level: number;
  cost: number;
}

export const mockUpgrades: Upgrade[] = [
  {
    upgrade_id: 1,
    upgrade_title: "Speed Boost",
    upgrade_description: "Increase speed by 10%",
    upgrade_level: 1,
    cost: 100,
  },
  {
    upgrade_id: 2,
    upgrade_title: "Double Jump",
    upgrade_description: "Allows double jump power",
    upgrade_level: 1,
    cost: 150,
  },
  {
    upgrade_id: 3,
    upgrade_title: "Shield",
    upgrade_description: "Gain a protective shield",
    upgrade_level: 1,
    cost: 200,
  },
  {
    upgrade_id: 4,
    upgrade_title: "Extra Life",
    upgrade_description: "Earn an extra life",
    upgrade_level: 1,
    cost: 250,
  },
  {
    upgrade_id: 5,
    upgrade_title: "Bird Radar",
    upgrade_description: "See hupgrade_idden birds on the map",
    upgrade_level: 1,
    cost: 180,
  },
  {
    upgrade_id: 6,
    upgrade_title: "Flight Duration",
    upgrade_description: "Increase flight time by 5 seconds",
    upgrade_level: 1,
    cost: 220,
  },
  {
    upgrade_id: 7,
    upgrade_title: "Upgrade Fire",
    upgrade_description: "Shoot 20% faster",
    upgrade_level: 1,
    cost: 300,
  },
  {
    upgrade_id: 8,
    upgrade_title: "Stealth Mode",
    upgrade_description: "Become invisible for 10 seconds",
    upgrade_level: 1,
    cost: 400,
  },
  {
    upgrade_id: 9,
    upgrade_title: "Health Boost",
    upgrade_description: "Increase maximum health by 20%",
    upgrade_level: 1,
    cost: 350,
  },
  {
    upgrade_id: 10,
    upgrade_title: "Lucky Charm",
    upgrade_description: "Increase chance of rare bird spawns",
    upgrade_level: 1,
    cost: 450,
  },
  {
    upgrade_id: 11,
    upgrade_title: "Power Strike",
    upgrade_description: "Increase attack power by 15%",
    upgrade_level: 1,
    cost: 380,
  },
  {
    upgrade_id: 12,
    upgrade_title: "Energy Regen",
    upgrade_description: "Recover energy 10% faster",
    upgrade_level: 1,
    cost: 500,
  },
];

export default mockUpgrades;
