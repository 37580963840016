import React, { createContext, useState, useContext, ReactNode } from "react";
import { Upgrade } from "../models/UpgradesModel";

interface PlayerContextType {
  birdsPoints: number;
  setBirdsPoints: React.Dispatch<React.SetStateAction<number>>;
  playerId: number;
  setPlayerId: React.Dispatch<React.SetStateAction<number>>;
  playerUpgrades: Upgrade[];
  setPlayerUpgrades: React.Dispatch<React.SetStateAction<Upgrade[]>>;
}

interface PlayerProviderProps {
  children: ReactNode;
}

export const PlayerContext = createContext<PlayerContextType | undefined>(
  undefined
);

export const PlayerProvider: React.FC<PlayerProviderProps> = ({ children }) => {
  const [birdsPoints, setBirdsPoints] = useState<number>(0);
  const [playerId, setPlayerId] = useState<number>(0);
  const [playerUpgrades, setPlayerUpgrades] = useState<Upgrade[]>([]);

  return (
    <PlayerContext.Provider
      value={{
        birdsPoints,
        setBirdsPoints,
        playerId,
        setPlayerId,
        playerUpgrades,
        setPlayerUpgrades,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error("usePlayer must be used within a PlayerProvider");
  }
  return context;
};
