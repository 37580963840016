// src/screens/BirdsScreen.tsx
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useGame } from "../context/GameContext"; // Importer useGame
import "./BirdsScreen.css";
import Birdie from "../components/Birdie";

const MAXIMUM_BIRDS = 10;
const NEW_BIRD_DELAY = 300;
const TIMER_DURATION = 30; // Durée du timer en secondes
const BIRD_REAPPEAR_DELAY = 350; // Délai avant que l'oiseau réapparaisse
const MAX_GAMES = 3; // Nombre maximum de parties par jour
const COOLDOWN_PERIOD = 20 * 1000; // 20 secondes

const getRandomPosition = (maxWidth: number, maxHeight: number) => ({
  top: Math.floor(Math.random() * (maxHeight - 60)) + 60,
  left: Math.floor(Math.random() * (maxWidth - 50)),
});

const getRandomVelocity = () => {
  const speedX = Math.floor(Math.random() * 3) + 1;
  const speedY = Math.floor(Math.random() * 3) + 1;
  return {
    dx: Math.random() < 0.5 ? speedX : -speedX,
    dy: Math.random() < 0.5 ? speedY : -speedY,
  };
};

const BirdsScreen: React.FC = () => {
  const { gamesPlayed, setGamesPlayed, cooldownEnd, setCooldownEnd } =
    useGame(); // Utiliser le contexte
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isEndModalVisible, setIsEndModalVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(TIMER_DURATION);
  const [clickedBirds, setClickedBirds] = useState(0);
  const [remainingTime, setRemainingTime] = useState("");

  const birdIdCounter = useRef(0);
  const positionsRef = useRef<{ [key: number]: { top: number; left: number } }>(
    {}
  );
  const velocitiesRef = useRef<{ [key: number]: { dx: number; dy: number } }>(
    {}
  );
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [birds, setBirds] = useState<{ id: number; isVisible: boolean }[]>([]);

  const addBird = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const newBirdId = birdIdCounter.current++;

    positionsRef.current[newBirdId] = getRandomPosition(width, height);
    velocitiesRef.current[newBirdId] = getRandomVelocity();
    setBirds((prevBirds) => [...prevBirds, { id: newBirdId, isVisible: true }]);
  }, []);

  useEffect(() => {
    if (isModalVisible || timeLeft <= 0) return;

    intervalRef.current = setInterval(() => {
      if (birds.length < MAXIMUM_BIRDS) {
        addBird();
      }
    }, NEW_BIRD_DELAY);
    return () => clearInterval(intervalRef.current!);
  }, [isModalVisible, timeLeft, birds.length, addBird]);

  useEffect(() => {
    if (timeLeft > 0 && !isModalVisible) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
    if (timeLeft === 0) {
      setIsEndModalVisible(true);
      setBirds([]);
    }
  }, [timeLeft, isModalVisible]);

  useEffect(() => {
    if (!cooldownEnd) return;

    const updateRemainingTime = () => {
      const diff = cooldownEnd.getTime() - Date.now();
      if (diff <= 0) {
        resetGame();
      } else {
        setRemainingTime(new Date(diff).toISOString().substr(11, 8));
      }
    };

    updateRemainingTime();
    const interval = setInterval(updateRemainingTime, 1000);
    return () => clearInterval(interval);
  }, [cooldownEnd]);

  const resetGame = () => {
    setRemainingTime("");
    setIsModalVisible(true);
    setTimeLeft(TIMER_DURATION);
    setClickedBirds(0);
    setGamesPlayed(0);
    setCooldownEnd(null);
  };

  const handleBirdClicked = useCallback((id: number) => {
    setBirds((prevBirds) =>
      prevBirds.map((bird) =>
        bird.id === id ? { ...bird, isVisible: false } : bird
      )
    );
    setClickedBirds((prev) => prev + 1);
    setTimeout(() => {
      setBirds((prevBirds) =>
        prevBirds.map((bird) =>
          bird.id === id ? { ...bird, isVisible: true } : bird
        )
      );
    }, BIRD_REAPPEAR_DELAY);
  }, []);

  const handleStartGame = () => {
    const now = new Date();
    if (gamesPlayed < MAX_GAMES || (cooldownEnd && now >= cooldownEnd)) {
      setIsModalVisible(false);
      setTimeLeft(TIMER_DURATION);
      setClickedBirds(0);
      setGamesPlayed((prev) => (prev < MAX_GAMES ? prev + 1 : 1));
      setCooldownEnd(null);
    }
  };

  useEffect(() => {
    if (gamesPlayed >= MAX_GAMES) {
      const now = new Date();
      if (!cooldownEnd) {
        setCooldownEnd(new Date(now.getTime() + COOLDOWN_PERIOD));
      } else if (now >= cooldownEnd) {
        resetGame();
      }
    }
  }, [gamesPlayed]);

  const handleCloseEndModal = () => {
    setIsEndModalVisible(false);
    setBirds([]);
    setIsModalVisible(true);
    setTimeLeft(TIMER_DURATION);
  };

  return (
    <div className="birdsScreen-container">
      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>
              Catch the birdies {MAX_GAMES - gamesPlayed}/{MAX_GAMES}
            </h2>
            {gamesPlayed < MAX_GAMES ? (
              <>
                <p>Tap on birdies for 30 seconds</p>
                <p>1 birdie = 1 crypto BINV</p>
                <p>1 crypto BINV ~ 0.01$</p>
                <button className="modal-button" onClick={handleStartGame}>
                  Start
                </button>
              </>
            ) : (
              <p>
                You have used all your games for today. Time remaining:{" "}
                {remainingTime}
              </p>
            )}
          </div>
        </div>
      )}
      {isEndModalVisible && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Time's up!</h2>
            <p>You tapped on {clickedBirds} birdies.</p>
            <button className="modal-button" onClick={handleCloseEndModal}>
              Close
            </button>
          </div>
        </div>
      )}
      {!isModalVisible && !isEndModalVisible && (
        <div className="timer">{timeLeft}</div>
      )}
      {birds.map(
        (bird) =>
          bird.isVisible && (
            <Birdie
              key={bird.id}
              initialPosition={positionsRef.current[bird.id]}
              initialVelocity={velocitiesRef.current[bird.id]}
              onClick={() => handleBirdClicked(bird.id)}
            />
          )
      )}
    </div>
  );
};

export default BirdsScreen;
