export interface Leaderboard {
  id: number;
  first_name: string;
  birdsPoints: number;
}

export const mockLeaderboard: Leaderboard[] = [
  {
    id: 1,
    first_name: "Lafleche",
    birdsPoints: 113453543,
  },
  {
    id: 2,
    first_name: "Polo",
    birdsPoints: 3453543,
  },
  {
    id: 3,
    first_name: "Caz",
    birdsPoints: 3543543,
  },
  {
    id: 4,
    first_name: "Trik",
    birdsPoints: 13453543,
  },
  {
    id: 5,
    first_name: "Pzdl",
    birdsPoints: 42452452,
  },
  {
    id: 6,
    first_name: "Mama",
    birdsPoints: 43543543,
  },
  {
    id: 7,
    first_name: "Pouste",
    birdsPoints: 823543543,
  },
  {
    id: 8,
    first_name: "Vurie",
    birdsPoints: 45365434,
  },
  {
    id: 9,
    first_name: "Anere",
    birdsPoints: 453543345,
  },
  {
    id: 10,
    first_name: "Alala",
    birdsPoints: 37070753,
  },
];
