import React, { useState, useEffect, useRef } from "react";
//import birdyImage from "../images/birdy.gif";
import birdyImage from "../images/birds/BirdieBis.png";
import { motion } from "framer-motion";
import { usePlayer } from "../context/PlayerContext"; // Import du contexte

const MORE_HEIGHT = 60;

interface BirdProps {
  initialPosition: { top: number; left: number };
  initialVelocity: { dx: number; dy: number };
  onClick: () => void;
}

const Birdie: React.FC<BirdProps> = ({
  initialPosition,
  initialVelocity,
  onClick,
}) => {
  const { birdsPoints, setBirdsPoints } = usePlayer();
  const [isVisible, setIsVisible] = useState(true);
  const [birdPosition, setBirdPosition] = useState(initialPosition);
  const [velocity, setVelocity] = useState(initialVelocity);
  const birdRef = useRef<HTMLImageElement>(null);
  const [clickEffect, setClickEffect] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const handleClick = (e: React.MouseEvent) => {
    const container = document.querySelector(
      ".birdsScreen-container"
    ) as HTMLElement;
    const containerRect = container.getBoundingClientRect(); // Obtenez la position du conteneur par rapport à la fenêtre

    const clickX = e.clientX - containerRect.left;
    const clickY = e.clientY - containerRect.top;

    const birdWidth = birdRef.current?.clientWidth || 50;
    const birdHeight = birdRef.current?.clientHeight || 50;

    // Ajustez les coordonnées du clic pour correspondre au centre de l'image
    const adjustedClickX = clickX - birdWidth / 2 + 20;
    const adjustedClickY = clickY - birdHeight / 2 + 20;

    setClickEffect({ x: adjustedClickX, y: adjustedClickY });

    setBirdsPoints(birdsPoints + 1);
    setIsVisible(false);

    // Appelle la fonction onClick pour notifier BirdsScreen
    onClick();

    // Supprimer l'effet après 0.5 seconde
    setTimeout(() => {
      setClickEffect(null);
    }, 500);
  };

  useEffect(() => {
    const moveBird = () => {
      setBirdPosition((prevPosition) => {
        const newLeft = prevPosition.left + velocity.dx;
        const newTop = prevPosition.top + velocity.dy;
        const birdWidth = birdRef.current?.clientWidth || 50;
        const birdHeight = birdRef.current?.clientHeight || 50;

        const container = document.querySelector(
          ".birdsScreen-container"
        ) as HTMLElement;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight + MORE_HEIGHT;

        // Détecte collision avec les bords du conteneur
        let newDx = velocity.dx;
        let newDy = velocity.dy;

        if (newLeft <= 0 || newLeft + birdWidth >= containerWidth) {
          newDx = -velocity.dx; // Rebond horizontal
        }

        if (newTop <= 20 || newTop + birdHeight >= containerHeight - 60) {
          // Limiter avec header et footer
          newDy = -velocity.dy; // Rebond vertical
        }

        setVelocity({ dx: newDx, dy: newDy });

        return {
          left: Math.min(Math.max(newLeft, 0), containerWidth - birdWidth),
          top: Math.min(
            Math.max(newTop, 20),
            containerHeight - birdHeight - 60
          ),
        };
      });
    };

    if (isVisible) {
      const interval = setInterval(moveBird, 25);
      return () => clearInterval(interval);
    }
  }, [velocity, isVisible]);

  return (
    <div>
      {isVisible ? (
        <img
          ref={birdRef}
          src={birdyImage}
          alt="Flying bird"
          onClick={handleClick}
          style={{
            position: "absolute",
            top: `${birdPosition.top}px`,
            left: `${birdPosition.left}px`,
            cursor: "pointer",
          }}
        />
      ) : (
        clickEffect && (
          <motion.div
            className="click-effect"
            initial={{ opacity: 1, scale: 0.5 }}
            animate={{ opacity: 0, scale: 2 }}
            transition={{ duration: 0.5 }}
            style={{ top: clickEffect.y, left: clickEffect.x }}
          />
        )
      )}
    </div>
  );
};

export default Birdie;
