import React, { useState, useEffect } from "react";
import { FaUserFriends } from "react-icons/fa";
import { IconContext } from "react-icons";
import { GiNestBirds, GiUpgrade } from "react-icons/gi";
import { MdLeaderboard } from "react-icons/md";
import { FaTasks } from "react-icons/fa";

interface FooterProps {
  setActiveScreen: (screen: string) => void;
}

const Footer: React.FC<FooterProps> = ({ setActiveScreen }) => {
  const [selectedMenu, setSelectedMenu] = useState("Birds");

  const handleMenuClick = (screen: string) => {
    setSelectedMenu(screen);
    setActiveScreen(screen);
  };

  useEffect(() => {
    setSelectedMenu("Birds");
  }, []);

  return (
    <div className="footer">
      <IconContext.Provider value={{ color: "white", size: "50px" }}>
        <button
          className={selectedMenu === "Birds" ? "selected" : ""}
          onClick={() => handleMenuClick("Birds")}
        >
          <GiNestBirds size={35} />
        </button>
        <button
          className={selectedMenu === "Upgrades" ? "selected" : ""}
          onClick={() => handleMenuClick("Upgrades")}
        >
          <GiUpgrade size={35} />
        </button>
        <button
          className={selectedMenu === "Friends" ? "selected" : ""}
          onClick={() => handleMenuClick("Friends")}
        >
          <FaUserFriends size={35} />
        </button>
        <button
          className={selectedMenu === "LeaderBoard" ? "selected" : ""}
          onClick={() => handleMenuClick("LeaderBoard")}
        >
          <MdLeaderboard size={35} />
        </button>
        <button
          className={selectedMenu === "Mission" ? "selected" : ""}
          onClick={() => handleMenuClick("Mission")}
        >
          <FaTasks size={35} />
        </button>
      </IconContext.Provider>
    </div>
  );
};

export default Footer;
