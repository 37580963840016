import { useState, useEffect } from "react";
import "./UpgradesScreen.css";
import { toast } from "react-toastify";
import { getUpgrades, purchaseUpgrade } from "../back/api";
import { usePlayer } from "../context/PlayerContext";
import { Upgrade } from "../models/UpgradesModel";
import { GiNestBirds } from "react-icons/gi";
import { ClipLoader } from "react-spinners";

const UpgradesScreen = () => {
  const [showPopin, setShowPopin] = useState(false);
  const [selectedUpgrade, setSelectedUpgrade] = useState<Upgrade>();
  const {
    birdsPoints,
    setBirdsPoints,
    playerId,
    playerUpgrades,
    setPlayerUpgrades,
  } = usePlayer();
  const [upgrades, setUpgrades] = useState<Upgrade[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUpgrades = async () => {
      try {
        setLoading(true);
        const upgradesFromDB: Upgrade[] = await getUpgrades();
        setUpgrades(upgradesFromDB);
      } catch (error) {
        console.error("Error fetching upgrades:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUpgrades();
  }, []);

  const openPopin = (upgrade: Upgrade) => {
    setSelectedUpgrade(upgrade);
    setShowPopin(true);
  };

  const closePopin = () => {
    setShowPopin(false);
  };

  const getUpgradeLevel = (upgradeId: any) => {
    const playerUpgrade = playerUpgrades.find(
      (u) => u.upgrade_id === upgradeId
    );
    return playerUpgrade ? playerUpgrade.upgrade_level : 0;
  };

  const calculateUpgradeCost = (baseCost: number, level: number) => {
    return Math.round(baseCost * Math.pow(1.2, level));
  };

  const buyUpgrade = async () => {
    if (selectedUpgrade) {
      const currentLevel = getUpgradeLevel(selectedUpgrade.upgrade_id);
      const upgradeCost = calculateUpgradeCost(
        selectedUpgrade.cost,
        currentLevel
      );

      if (birdsPoints >= upgradeCost) {
        try {
          // Effectuer l'achat de l'upgrade
          await purchaseUpgrade(playerId, selectedUpgrade.upgrade_id);

          // Mise à jour des pièces
          setBirdsPoints(birdsPoints - upgradeCost);

          // Log pour vérifier les données
          console.log("Selected Upgrade ID:", selectedUpgrade.upgrade_id);
          console.log("Player Upgrades:", playerUpgrades);

          // Mise à jour des upgrades du joueur
          setPlayerUpgrades((actualUpgrades) => {
            console.log("selectedUpgrade : ", selectedUpgrade);
            // Si l'amélioration existe déjà, on incrémente son niveau
            if (selectedUpgrade.upgrade_level > 0) {
              const updatedUpgrades = [...actualUpgrades, selectedUpgrade];
              console.log("AMELIORATION EXISTANTE");
              return updatedUpgrades;
            } else {
              // Si l'amélioration n'existe pas, on l'ajoute avec le niveau initialisé à 1
              console.log("AMELIORATION NOUVELLE");
              return [...actualUpgrades, { ...selectedUpgrade, level: 1 }];
            }
          });

          toast.success("Upgrade bought!");

          // Fermer la popin après l'achat
          closePopin();
        } catch (error) {
          toast.error("Error purchasing upgrade, try later!");
          console.error("Error purchasing upgrade:", error);
        }

        setSelectedUpgrade(undefined);
      } else {
        toast.error("Not enough birdies !");
      }
    }
  };

  // Fonction pour obtenir le prochain upgrade non acheté d'un type spécifique
  const getNextAvailableUpgrade = (upgradesByType: any[]) => {
    // Trier les améliorations par niveau
    const sortedUpgrades = upgradesByType.sort(
      (a: { upgrade_level: number }, b: { upgrade_level: number }) =>
        a.upgrade_level - b.upgrade_level
    );

    // Parcourir les améliorations et trouver la première amélioration qui n'a pas encore été achetée
    for (const upgrade of sortedUpgrades) {
      const playerUpgrade = playerUpgrades.find(
        (u) => u.upgrade_id === upgrade.upgrade_id
      );

      if (
        !playerUpgrade ||
        playerUpgrade.upgrade_level < upgrade.upgrade_level
      ) {
        return upgrade; // Retourner la première amélioration non achetée
      }
    }

    // Si toutes les améliorations ont été achetées, retourner null ou la dernière amélioration
    return null;
  };

  const upgradeTypes = [
    ...new Set(upgrades.map((u: Upgrade) => u.upgrade_title)),
  ];

  return (
    <div className="upgrade-screen">
      <h1 className="upgrade-title">Upgrades</h1>
      {loading ? (
        <div className="loader">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="upgrades-container">
          {upgradeTypes.map((type) => {
            const upgradesByType = upgrades.filter(
              (upgrade: Upgrade) => upgrade.upgrade_title === type
            );
            const nextUpgrade = getNextAvailableUpgrade(upgradesByType);

            if (!nextUpgrade) {
              return <p key={type}>All upgrades purchased for {type}!</p>;
            }

            const isPurchased = nextUpgrade.upgrade_level > 1;

            return (
              <button
                key={nextUpgrade.upgrade_id}
                className={`upgrade-item ${
                  isPurchased ? "purchased" : "not-purchased"
                }`}
                onClick={() => openPopin(nextUpgrade)}
              >
                <div className="upgrade-item-details">
                  <p className="upgrade-item-title">
                    {nextUpgrade.upgrade_title}
                  </p>
                  <p className="upgrade-item-description">
                    {nextUpgrade.upgrade_description}
                  </p>
                  <div className="upgrade-info">
                    <div className="level">
                      Level {nextUpgrade.upgrade_level}
                    </div>
                    <div className="upgrade-cost">
                      <div className="birdsPoints">
                        {calculateUpgradeCost(
                          nextUpgrade.cost,
                          getUpgradeLevel(nextUpgrade.upgrade_id)
                        )}
                        <GiNestBirds
                          style={{ color: "#4FFF57", fontSize: "20px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`mask ${isPurchased ? "" : "not-purchased"}`}
                ></div>
              </button>
            );
          })}
        </div>
      )}

      {showPopin && selectedUpgrade && (
        <div className="modal-overlay" onClick={closePopin}>
          <div className="modal" onClick={(e) => e.stopPropagation()}>
            <h2>{selectedUpgrade.upgrade_title}</h2>
            <p>{selectedUpgrade.upgrade_description}</p>
            <p className="costUpgrade">
              {calculateUpgradeCost(
                selectedUpgrade.cost,
                getUpgradeLevel(selectedUpgrade.upgrade_id)
              )}
              <GiNestBirds style={{ color: "#4FFF57", fontSize: "30px" }} />
            </p>
            <button className="modal-button" onClick={buyUpgrade}>
              Buy
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpgradesScreen;
