import React, { createContext, useContext, useState, ReactNode } from "react";

interface GameContextType {
  gamesPlayed: number;
  setGamesPlayed: React.Dispatch<React.SetStateAction<number>>;
  cooldownEnd: Date | null;
  setCooldownEnd: React.Dispatch<React.SetStateAction<Date | null>>;
}

const GameContext = createContext<GameContextType | undefined>(undefined);

export const GameProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [cooldownEnd, setCooldownEnd] = useState<Date | null>(null);

  return (
    <GameContext.Provider
      value={{ gamesPlayed, setGamesPlayed, cooldownEnd, setCooldownEnd }}
    >
      {children}
    </GameContext.Provider>
  );
};

export const useGame = (): GameContextType => {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error("useGame must be used within a GameProvider");
  }
  return context;
};
