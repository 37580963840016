import React from "react";
import { usePlayer } from "./context/PlayerContext";
import logoImage from "./images/Logo.png";
import { GiNestBirds } from "react-icons/gi";

const Header: React.FC = () => {
  const { birdsPoints } = usePlayer();
  const playerName = "Archy";

  const formattedBirdsPoints = birdsPoints.toLocaleString("fr-FR");

  return (
    <div className="header">
      <div className="header-left">
        <img className="logo" src={logoImage} alt="Birds Invasion" />
        <h3>{playerName}</h3>
      </div>

      <div className="header-right">
        <h3>{formattedBirdsPoints}</h3>

        <GiNestBirds style={{ color: "#4FFF57", fontSize: "35px" }} />
      </div>
    </div>
  );
};

export default Header;
