import { Friend, mockFriends } from "../models/FriendsModel";
import { Leaderboard, mockLeaderboard } from "../models/LeaderboardModel";
import { Mission, mockMissions } from "../models/MissionModel";
import mockUpgrades, { Upgrade } from "../models/UpgradesModel";

const apiUrl = "http://localhost:5000/api";

// TOKEN TELEGRAM : 7812017995:AAEzFiGCC4XE9pWHNw_gNzJFL0PAWymmCqQ

export const saveBirdsPoints = async (birdsPoints: number) => {
  try {
    const response = await fetch(`${apiUrl}/saveBirdsPoints`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ birdsPoints }),
    });

    if (!response.ok) {
      throw new Error(`Erreur HTTP ! statut: ${response.status}`);
    }

    return response.json(); // Si tu veux traiter la réponse
  } catch (error) {
    console.error("Erreur lors de la sauvegarde des birdsPoints :", error);
    throw error;
  }
};

export const getUpgrades = async (): Promise<Upgrade[]> => {
  try {
    /*
    const response = await fetch(`${apiUrl}/upgrades`);
    const data = await response.json();
    return data;
    */

    // Simulez un délai comme si vous attendiez une réponse d'une API
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockUpgrades);
      }, 1000); // Délai de 1 seconde pour simuler un appel d'API
    });
  } catch (error) {
    console.error("Error fetching upgrades:", error);
    throw error;
  }
};

export const purchaseUpgrade = async (userId: number, upgradeId: number) => {
  try {
    const response = await fetch(`${apiUrl}/player/upgrades`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId, upgradeId }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error purchasing upgrade:", error);
    throw error;
  }
};

export const getFriends = async (userId: number): Promise<Friend[]> => {
  try {
    /*
    const response = await fetch(`${apiUrl}/player/invites/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch invites: ${response.statusText}`);
    }

    const invites = await response.json();
    return invites.results;
    */
    return new Promise((resolve) => {
      setTimeout(() => {
        const sortedFriends = mockFriends.sort(
          (a, b) => b.birdsPoints - a.birdsPoints
        );
        resolve(sortedFriends);
      }, 1000); // Délai de 1 seconde pour simuler un appel d'API
    });
  } catch (error) {
    console.error("Error fetching invites:", error);
    throw error;
  }
};

export const getLeaderboard = async (): Promise<Leaderboard[]> => {
  try {
    /*
    const response = await fetch(`${apiUrl}/player/invites/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch invites: ${response.statusText}`);
    }

    const invites = await response.json();
    return invites.results;
    */
    return new Promise((resolve) => {
      setTimeout(() => {
        const sortedLeaderboard = mockLeaderboard.sort(
          (a, b) => b.birdsPoints - a.birdsPoints
        );
        resolve(sortedLeaderboard);
      }, 1000); // Délai de 1 seconde pour simuler un appel d'API
    });
  } catch (error) {
    console.error("Error fetching invites:", error);
    throw error;
  }
};

export const addFriend = async (referrerId: number, playerId: number) => {
  try {
    const response = await fetch(`${apiUrl}/addFriend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ referrerId, playerId }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error adding friend:", error);
    throw error;
  }
};

export const getMissions = async (playerId: number): Promise<Mission[]> => {
  try {
    /*
    const response = await fetch(`${apiUrl}/missions?playerId=${playerId}`);
    const data = await response.json();
    return data;
    */
    return new Promise((resolve) => {
      setTimeout(() => {
        const sortedLeaderboard = mockMissions.sort((a, b) => a.id - b.id);
        resolve(sortedLeaderboard);
      }, 1000); // Délai de 1 seconde pour simuler un appel d'API
    });
  } catch (error) {
    throw new Error("Error fetching missions");
  }
};

export const completeMission = async (playerId: number, missionId: number) => {
  try {
    await fetch(`${apiUrl}/missions/complete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ playerId, missionId }),
    });
  } catch (error) {
    throw new Error("Error completing mission");
  }
};
